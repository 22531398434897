<template>
  <Form class="bookingForm" v-slot="{ invalid, changed }">
    <div class="bookingForm__wrapper">
      <div class="grid-2 mb-24">
        <FormItem
          rules="required"
          class="grid-2__full-item bookingForm__productRow"
        >
          <Select
            v-model="form.productId"
            :options="productOptions"
            :label="$t('Product')"
            :placeholder="$t('Product')"
            :is-disabled="isEditing"
            :is-dark-border="false"
          />
          <Checkbox v-model="form.isWalkIn" :is-disabled="isEditing">
            Walk-in
          </Checkbox>
        </FormItem>
        <FormItem
          v-if="
            selectedProduct &&
            selectedProduct.typeOfPricing === BookingPricingTypeEnum.GROUP_RANGE
          "
          rules="required"
          class="grid-2__full-item"
        >
          <p class="bookingForm__label">Select group size</p>
          <ButtonsGroup
            v-model="selectedGroup"
            variant="primary"
            :options="groupList"
          />
          <FormItem>
            <div class="mt-24">
              <Checkbox
                v-if="
                  selectedProduct &&
                  selectedProduct.typeOfPricing ===
                    BookingPricingTypeEnum.GROUP_RANGE &&
                  form.selectedGroupId
                "
                v-model="form.isSpecifyGroupSize"
              >
                Specify group size
              </Checkbox>
            </div>
          </FormItem>
        </FormItem>
        <FormItem
          v-if="
            (selectedProduct &&
              selectedProduct.typeOfPricing ===
                BookingPricingTypeEnum.GROUP_RANGE &&
              form.isSpecifyGroupSize) ||
            (selectedProduct &&
              selectedProduct.typeOfPricing === BookingPricingTypeEnum.PERSON)
          "
          :rules="{
            required: true,
            minValue: form.isWalkIn ? 1 : minPlayers,
            maxValue: maxPlayers,
          }"
          v-slot="{ isError }"
        >
          <Input
            v-model="innerPlayersCount"
            v-mask="'######'"
            :label="$t('Number of participants')"
            :placeholder="$t('Number of participants')"
            :is-disabled="!form.productId"
            :is-error="isError"
          />
        </FormItem>
        <FormItem v-if="!form.isWalkIn" rules="required">
          <booking-calendar
            v-model="dateSlots"
            :base-slots="value.slots"
            :label="$t('Date/time')"
            :placeholder="$t('Date/time')"
            :players-count="
              selectedProduct &&
              selectedProduct.typeOfPricing ===
                BookingPricingTypeEnum.GROUP_RANGE
                ? groupCount
                : innerPlayersCount
            "
            :product-id="form.productId"
            :product="selectedProduct"
            :dates="dates"
            :isHighLight="isHighLight"
            :is-loading="isDatesLoading"
            :is-disabled="
              !selectedProduct ||
              (selectedProduct.typeOfPricing ===
                BookingPricingTypeEnum.PERSON &&
                (innerPlayersCount > maxPlayers ||
                  innerPlayersCount < minPlayers))
            "
            :max-players="maxPlayers"
            :min-players="minPlayers"
            @date-change="handleDateChange"
          />
        </FormItem>
        <FormItem v-else rules="required">
          <WalkInTimeInput v-model="walkInTime" />
        </FormItem>
        <FormItem rules="required">
          <Input
            v-model="form.reservationInfo.firstName"
            :label="$t('Customer first name')"
            :placeholder="$t('First name')"
          />
        </FormItem>
        <FormItem rules="required">
          <Input
            v-model="form.reservationInfo.lastName"
            :label="$t('Customer last name')"
            :placeholder="$t('Last name')"
          />
        </FormItem>
        <FormItem rules="required|email">
          <Input
            v-model="form.reservationInfo.email"
            :label="$t('Customer email')"
            :placeholder="$t('Email')"
          />
        </FormItem>
        <FormItem rules="required">
          <Input
            v-model="form.reservationInfo.phone"
            v-mask="'+1 (###) ###-####'"
            :label="$t('Customer phone number')"
            :placeholder="$t('Phone number')"
          />
        </FormItem>
        <FormItem v-if="affiliateOptions.length">
          <Select
            v-model="form.affiliateId"
            :options="affiliateOptions"
            :label="$t('Affiliate')"
            :placeholder="$t('Affiliate')"
            :is-dark-border="false"
          />
        </FormItem>
        <FormItem>
          <Input
            v-model="form.reservationInfo.note"
            :label="$t('Note')"
            :placeholder="$t('Note')"
          />
        </FormItem>
      </div>
      <CustomFieldsInput
        v-if="
          form.reservationInfo.customFields &&
          Object.keys(form.reservationInfo.customFields).length
        "
        v-model="form.reservationInfo.customFields"
        has-labels
        class="mt-24"
      />
      <Checkbox
        v-if="!isEditing"
        v-model="form.isConfirmationEmail"
        :is-disabled="isEditing"
      >
        Send a confirmation email
      </Checkbox>
      <div class="divider my-40" />
      <template v-if="!isEditing && (isUpsellLoading || upsellItems.length)">
        <Loader
          v-if="isUpsellLoading"
          class="mx-auto"
          color="primary"
          size="m"
        />
        <div v-else class="grid-2">
          <UpsellItemCard
            v-for="item in upsellItems"
            :key="item.id"
            :value="item.quantity"
            is-counter
            :title="item.name"
            :image="item.image"
            :description="item.description"
            :price="item.price"
            @input="handleUpsellQuantityChange(item, $event)"
          />
        </div>
        <div class="divider my-40" />
      </template>
      <div
        v-if="
          selectedProduct &&
          selectedProduct.depositType &&
          (!checkoutPrice || !checkoutPrice.paid) &&
          selectedVenue.widget !== WidgetThemeEnum.BADAXE
        "
      >
        <div class="bookingForm__deposit">
          <RadioGroup
            v-model="isDeposit"
            :label="depositTitle"
            :options="radioOptions"
            :isRow="true"
            variant="black"
          />
        </div>
        <div class="divider my-40" />
      </div>
      <div style="position: relative">
        <BookingPriceBreakdown
          :price-data="isEditing ? bookingPrice : checkoutPrice"
          :edit-price-data="isEditing ? checkoutPrice : null"
          :players-count="isEditing ? +value.playersCount : innerPlayersCount"
          :edit-players-count="isEditing ? innerPlayersCount : null"
          :pay-now="isEditing ? additionalAmount : totalPrice"
          :product-name="selectedProduct && selectedProduct.name"
        />
        <div v-if="isPriceLoading" class="overlay-loader">
          <Loader color="primary" size="m" class="mx-auto" />
        </div>
      </div>
      <div class="bookingForm__additional-settings mt-24">
        <div class="bookingForm__additional-settings__checkboxes">
          <div v-if="terms" class="bookingForm__terms">
            <Checkbox v-model="isAcceptTerms" />
            <span class="bookingForm__terms__text">
              I accept
              <a class="bookingForm__terms__text__link" @click.stop="openTerms">
                the terms and conditions
              </a>
            </span>
          </div>
          <div
            v-if="
              !form.isWalkIn &&
              selectedProduct &&
              selectedProduct.typeOfPricing ===
                BookingPricingTypeEnum.GROUP_RANGE
            "
            class="bookingForm__terms"
          >
            <Checkbox v-model="form.isFullFirstPayment" />
            <span class="bookingForm__terms__text">
              Pay full booking price (for group maximum size)
            </span>
          </div>
          <div v-if="isShowOverbookOption" class="bookingForm__terms m-warning">
            <Checkbox v-model="form.isAllowOverbook" />
            <span class="bookingForm__terms__text"> Allow overbook </span>
          </div>
        </div>
        <Guard permission="bookings.setCustomPrice" v-slot="{ isAvailable }">
          <FormItem v-if="isAvailable" class="bookingForm__customPrice">
            <Input
              v-if="customPrice !== null"
              v-model="customPrice"
              placeholder="Custom price"
              :mask="dollarsMask"
            />
            <Button
              :is-block="['xs', 'sm'].includes($mq)"
              is-outlined
              variant="secondary"
              @click="toggleCustomPrice"
            >
              {{
                customPrice !== null
                  ? "Cancel custom price"
                  : "Set custom price"
              }}
            </Button>
          </FormItem>
        </Guard>
      </div>
    </div>
    <Guard permission="bookings.collect" v-slot="{ isAvailable }">
      <div class="bookingForm__actions">
        <template v-if="isAvailable">
          <Button
            class="bookingForm__button"
            v-if="!isEditing && checkoutPrice && checkoutPrice.total"
            :is-disabled="
              invalid ||
              !checkoutPrice ||
              !changed ||
              !isWalkInTimeValid ||
              (!form.isWalkIn && !form.slots.length) ||
              isSubmittingSkipPayment ||
              isPriceLoading ||
              isDatesLoading ||
              (terms && !isAcceptTerms) ||
              (isShowOverbookOption && !form.isAllowOverbook)
            "
            :is-loading="isSubmitting && !isSkipPayment"
            :is-block="['xs', 'sm'].includes($mq)"
            @click="handleSubmit(false)"
          >
            {{ submitButtonText }}
          </Button>
          <Button
            v-else
            class="bookingForm__button"
            :is-disabled="
              invalid ||
              !checkoutPrice ||
              !changed ||
              !isWalkInTimeValid ||
              isSubmittingSkipPayment ||
              (!form.isWalkIn && !form.slots.length) ||
              isPriceLoading ||
              isDatesLoading ||
              (terms && !isAcceptTerms) ||
              (isShowOverbookOption && !form.isAllowOverbook)
            "
            :is-loading="isSubmitting && !isSkipPayment"
            :is-block="['xs', 'sm'].includes($mq)"
            @click="handleSubmit(false)"
          >
            {{ editingButtonText }}
          </Button>
        </template>
        <Button
          v-if="
            editingWithoutButtonText &&
            checkoutPrice &&
            (!isEditing || additionalAmount !== 0) &&
            (!isDeposit || !isDeposit.value)
          "
          isOutlined
          :is-disabled="
            invalid ||
            !checkoutPrice ||
            !changed ||
            !isWalkInTimeValid ||
            (!form.isWalkIn && !form.slots.length) ||
            isPriceLoading ||
            isDatesLoading ||
            (terms && !isAcceptTerms) ||
            (isShowOverbookOption && !form.isAllowOverbook)
          "
          :is-loading="isSubmittingSkipPayment"
          :is-block="['xs', 'sm'].includes($mq)"
          @click="handleSubmit(true)"
        >
          {{ editingWithoutButtonText }}
        </Button>
      </div>
    </Guard>
    <TermsModal
      v-if="terms"
      :name="termsModalName"
      :text="sanitize(terms.text)"
    />
    <WithoutPaymentOptionDialog />
  </Form>
</template>

<script>
import BookingCalendar from "./BookingCalendar";
import { mapActions, mapMutations, mapState } from "vuex";
import {
  formatMoney,
  getBookingPrice,
  getCurrencyByCountryCode,
  getMinutes,
  getTimezoneOffsetHours,
  getVenueTimeFormatHours,
  getWalkInTimeSave,
  sanitize,
} from "@/helpers/utils";
import moment from "moment";
import { debounce } from "debounce";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import CustomFieldsInput from "@/components/common/customFields/CustomFieldsInput";
import TermsModal from "@/components/terms/TermsModal";
import { getDocById } from "@/helpers/firebase-helpers";
import UpsellItemCard from "@/components/upsell-items/UpsellItemCard";
import axios from "axios";
import Guard from "@/components/common/Guard";
import WalkInTimeInput from "@/components/bookings/WalkInTimeInput";
import WithoutPaymentOptionDialog, {
  withoutPaymentOptionDialog,
} from "@/components/bookings/WithoutPaymentOptionDialog";
import {
  BookingPricingTypeEnum,
  PromoRateTypeEnum,
  WidgetThemeEnum,
} from "@/helpers/enums";
import BookingPriceBreakdown from "@/components/bookings/BookingPriceBreakdown.vue";
import dialog from "@/plugins/dialog";

export default {
  name: "BookingForm",
  components: {
    BookingPriceBreakdown,
    WalkInTimeInput,
    Guard,
    UpsellItemCard,
    TermsModal,
    CustomFieldsInput,
    BookingCalendar,
    WithoutPaymentOptionDialog,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    isShowOverbookOption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      BookingPricingTypeEnum,
      WidgetThemeEnum,
      form: {
        ...this.value,
      },
      isPriceLoading: false,
      checkoutPrice: null,
      editingPrice: null,
      isSkipPayment: false,
      isDatesLoading: false,
      isAcceptTerms: false,
      prices: [],
      dates: [],
      currentMonth: "",
      customPrice: this.value.checkoutInfo?.isCustom
        ? this.value.checkoutInfo.subtotal / 100
        : null,
      termsModalName: "termsModal",
      terms: null,
      upsellItems: [],
      isUpsellLoading: false,
      upsellQuantityTimeout: null,
      walkInTime: null,
      depositTitle:
        "You can pay a full price straight away, or just make a deposit and pay the rest \n" +
        "of the price later at the venue. How much would you like to pay now?",
      isDeposit: {},
      typeOfPricing: null,
      selectedGroup: null,
      oldSelectedGroup: null,
      typeOfPricingOptions: [
        {
          id: 1,
          name: "Per person",
          value: BookingPricingTypeEnum.PERSON,
        },
        {
          id: 2,
          name: `By group range`,
          value: BookingPricingTypeEnum.GROUP_RANGE,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      editedItem: (state) => state.bookings.editedItem,
      products: (state) => state.products.list,
      datesAvailabilities: (state) => state.bookings.datesAvailabilities,
      selectedVenue: (state) => state.venues.selectedVenue,
      venueUtcOffset: (state) => {
        return getTimezoneOffsetHours(state.venues.selectedVenue.timezone);
      },
      currencySymbol: (state) =>
        getCurrencyByCountryCode(state.venues.selectedVenue?.country).symbol,
      affiliateOptions: (state) =>
        state.affiliates.list.map((item) => ({
          name: item.name,
          value: item.id,
        })),
    }),
    innerPlayersCount: {
      get() {
        return +this.form.playersCount;
      },
      set: debounce(async function (val) {
        this.form.playersCount = val;
      }, 400),
    },
    dollarsMask() {
      return createNumberMask({
        allowDecimal: true,
        prefix: this.currencySymbol,
      });
    },
    groupCount() {
      return this.form.isSpecifyGroupSize
        ? this.innerPlayersCount
        : this.selectedGroup?.value?.maximum;
    },
    isEditing() {
      return !!this.value.id;
    },
    submitButtonText() {
      const action = this.checkoutPrice?.depositPrice ? "Deposit" : "Collect";
      const amount = (this.totalPrice / 100).toFixed(2);
      return `${this.$t("Save")} & ${this.$t(action)} $${amount}`;
    },
    editingButtonText() {
      if (this.isEditing && this.checkoutPrice && this.additionalAmount !== 0) {
        const action = this.additionalAmount > 0 ? "Collect" : "Refund";
        const amount = Math.abs(this.additionalAmount / 100).toFixed(2);

        return `${this.$t("Save")} & ${this.$t(action)} $${amount}`;
      }
      return this.$t("Save");
    },
    editingWithoutButtonText() {
      if (
        this.checkoutPrice &&
        this.checkoutPrice.depositPrice &&
        this.checkoutPrice.depositPrice <=
          this.checkoutPrice.paid - this.checkoutPrice.refunded
      ) {
        return null;
      }
      return this.additionalAmount >= 0
        ? `${this.$t(`Select payment method`)}`
        : `${this.$t(`Save without refund`)}`;
    },
    isHighLight() {
      return (
        this.form.productId &&
        !this.dateSlots.slots?.length &&
        !this.isDatesLoading
      );
    },
    dateSlots: {
      set({ date, slots }) {
        this.currentMonth = moment(date).format("YYYY-MM");
        this.dates =
          (this.datesAvailabilities &&
            this.datesAvailabilities[this.currentMonth]) ||
          null;
        this.form.date = date;
        this.form.slots = slots;
      },
      get() {
        return {
          date: this.form.date,
          slots: this.form.slots,
        };
      },
    },
    groupList() {
      return this.selectedProduct?.groups.map((group, index) => ({
        id: group.uuid,
        value: {
          minimum: group.minimum,
          maximum: group.maximum,
          personsToDeposit: group.personsToDeposit,
        },
        label: `${group.minimum}-${group.maximum}`,
        name: `group-${index}`,
      }));
    },
    isSubmittingSkipPayment() {
      return this.isSkipPayment && this.isSubmitting;
    },
    totalPrice() {
      if (this.checkoutPrice) {
        return ((this.selectedProduct?.depositType && this.isDeposit?.value) ||
          this.selectedGroup?.value.personsToDeposit) &&
          this.checkoutPrice.depositPrice
          ? this.checkoutPrice.depositPrice
          : this.checkoutPrice.total;
      }
      return 0;
    },
    additionalAmount() {
      if (!this.checkoutPrice || this.editingPrice === null) {
        return 0;
      }
      return (
        this.editingPrice -
        (this.checkoutPrice.paid || 0) +
        (this.checkoutPrice.refunded || 0)
      );
    },
    bookingPrice() {
      return (
        this.value.checkoutInfo && getBookingPrice(this.value.checkoutInfo)
      );
    },
    productOptions() {
      return this.products
        .filter((product) => !product.isPaused)
        .map((product) => ({
          value: product.id,
          name: product.name,
        }));
    },
    radioOptions() {
      if (!this.selectedProduct?.depositType) {
        return [];
      }

      const depositValue =
        this.selectedProduct.depositType === PromoRateTypeEnum.PERCENT
          ? `${this.selectedProduct.deposit}%`
          : formatMoney(
              this.selectedProduct.deposit,
              this.selectedVenue?.moneyFormat
            );

      return [
        {
          id: 1,
          name: "Full price at once",
          value: false,
        },
        {
          id: 2,
          name: `${depositValue} deposit `,
          value: true,
        },
      ];
    },
    selectedProduct() {
      return this.products.find((item) => item.id === this.form.productId);
    },
    minPlayers() {
      return (
        (this.typeOfPricing &&
        this.typeOfPricing.value === BookingPricingTypeEnum.PERSON &&
        !this.selectedGroup
          ? +this.selectedProduct?.minPlayers
          : +this.selectedGroup?.value.minimum) || 1
      );
    },
    maxPlayers() {
      return (
        (this.typeOfPricing &&
        this.typeOfPricing.value === BookingPricingTypeEnum.PERSON &&
        !this.selectedGroup
          ? +this.selectedProduct?.maxPlayers
          : +this.selectedGroup?.value.maximum) || Number.MAX_VALUE
      );
    },
    pureCustomPrice() {
      return this.customPrice !== null
        ? +this.customPrice.replace(/[CA$,]/g, "")
        : undefined;
    },
    isWalkInTimeValid() {
      if (this.form.isWalkIn) {
        if (this.walkInTime && this.walkInTime.from && this.walkInTime.to) {
          const slot = getWalkInTimeSave(this.walkInTime, this.venueUtcOffset);
          return getMinutes(slot.from) < getMinutes(slot.to);
        }
        return false;
      }
      return true;
    },
  },
  watch: {
    value(val) {
      this.form = val;
    },
    async "form.productId"(newValue, oldValue) {
      if (!this.isEditing && newValue && !oldValue) {
        this.form.date = moment().format("YYYY-MM-DD");
      }
      if (!this.form.isWalkIn) {
        await this.refetchDatesAvailabilities();
      }
      await this.calculatePrice();
      if (
        !this.innerPlayersCount ||
        (!this.form.isWalkIn && this.innerPlayersCount < this.minPlayers)
      ) {
        this.innerPlayersCount = this.minPlayers;
      } else if (this.innerPlayersCount > this.maxPlayers) {
        this.innerPlayersCount = this.maxPlayers;
      }
    },
    async innerPlayersCount() {
      if (!this.form.isWalkIn) {
        await this.refetchDatesAvailabilities();
      }
      if (this.customPrice === null) {
        await this.calculatePrice();
      }
    },
    "form.selectedGroupId": debounce(async function () {
      this.innerPlayersCount = this.maxPlayers;
      await this.calculatePrice();
    }, 400),
    "form.slots": debounce(async function () {
      if (this.customPrice === null) {
        await this.calculatePrice();
      }
    }, 800),
    customPrice: debounce(async function () {
      await this.calculatePrice();
    }, 400),
    isDeposit: debounce(async function () {
      await this.calculatePrice();
    }, 400),
    "form.isFullFirstPayment"() {
      this.calculatePrice();
    },
    async selectedProduct(val) {
      this.isDeposit = this.radioOptions[0];
      if (!this.isEditing) {
        const productCustomFields = val?.customFields || {};
        this.form.reservationInfo.customFields = JSON.parse(
          JSON.stringify(productCustomFields)
        );
      }
      await Promise.allSettled([
        (async () => {
          if (val.termsId) {
            this.terms = await getDocById(
              `/venues/${this.selectedVenue.id}/terms`,
              val.termsId
            );
          } else {
            this.terms = null;
          }
        })(),
        (async () => {
          if (!this.isEditing && val.upsellItemIds) {
            try {
              this.isUpsellLoading = true;
              this.upsellItems = (
                await Promise.all(
                  val.upsellItemIds.map(async (itemId) => {
                    const item = await getDocById(
                      `/venues/${this.selectedVenue.id}/upsellItems`,
                      itemId
                    );
                    if (item && !item.isPaused) {
                      return {
                        ...item,
                        quantity: 0,
                      };
                    }
                  })
                )
              ).filter(Boolean);
            } finally {
              this.isUpsellLoading = false;
            }
          } else {
            this.upsellItems = [];
          }
        })(),
      ]);
    },
    "form.isWalkIn"(newValue) {
      this.form.slots = [];
      if (newValue) {
        this.initWalkInTime();
      } else {
        this.walkInTime = null;
      }
    },
    selectedGroup(group) {
      this.form.selectedGroupId = group.id;
    },
    "form.isSpecifyGroupSize"() {
      if (this.selectedGroup?.value) {
        this.innerPlayersCount = this.selectedGroup?.value.maximum;
      }
    },
  },
  async created() {
    this.typeOfPricing = this.selectedProduct?.typeOfPricing
      ? this.typeOfPricingOptions.find(
          (option) => option.value === this.selectedProduct.typeOfPricing
        )
      : this.typeOfPricingOptions[0];

    if (this.isEditing) {
      this.checkoutPrice = getBookingPrice(this.value.checkoutInfo);

      if (this.editedItem) {
        const group = this.selectedProduct?.groups.find(
          (group) => group.uuid === this.editedItem.selectedGroupId
        );

        if (group) {
          this.selectedGroup = this.oldSelectedGroup = {
            id: group.uuid,
            value: {
              minimum: group.minimum,
              maximum: group.maximum,
              personsToDeposit: group.personsToDeposit,
            },
            label: `${group.minimum}-${group.maximum}`,
          };
        }
      }
    }

    if (this.value.isWalkIn) {
      const { from, to } = this.value.slots[0];
      this.initWalkInTime(from, to);
    }

    const month = moment(this.form.date).format("YYYY-MM");
    await Promise.allSettled([
      this.fetchProducts(),
      this.fetchAffiliates(),
      this.fetchAvailabilitiesDates(month),
    ]);
  },
  mounted() {
    this.isDeposit = this.radioOptions[0];
  },
  beforeDestroy() {
    this.REMOVE_FROM_DATES_AVAILABILITIES();
  },
  methods: {
    ...mapActions({
      getPrice: "bookings/getPrice",
      fetchProducts: "products/fetchProducts",
      getDates: "bookings/getDates",
      fetchAffiliates: "affiliates/fetchAffiliates",
    }),
    ...mapMutations({
      SET_DATES_AVAILABILITIES: "bookings/SET_DATES_AVAILABILITIES",
      REMOVE_FROM_DATES_AVAILABILITIES:
        "bookings/REMOVE_FROM_DATES_AVAILABILITIES",
    }),
    sanitize,
    async handleDateChange(date) {
      const month =
        date.month + 1 >= 10 ? date.month + 1 : `0${date.month + 1}`;
      const formattedMonth = `${date.year}-${month}`;

      await this.fetchAvailabilitiesDates(formattedMonth);
    },
    async fetchAvailabilitiesDates(month) {
      this.currentMonth = month;

      if (
        !this.form.productId ||
        this.innerPlayersCount > this.maxPlayers ||
        this.innerPlayersCount < this.minPlayers
      ) {
        return;
      }

      if (this.datesAvailabilities[month]) {
        this.dates =
          (this.datesAvailabilities &&
            this.datesAvailabilities[this.currentMonth]) ||
          null;
        return;
      }

      this.isDatesLoading = true;
      let isCanceled = false;
      try {
        const dates = await this.getDates({
          playersCount:
            this.form.typeOfPricing === BookingPricingTypeEnum.PERSON
              ? this.innerPlayersCount
              : this.groupCount || 1,
          month,
          productId: this.form.productId,
        });
        this.SET_DATES_AVAILABILITIES({
          ...this.datesAvailabilities,
          [month]: dates,
        });
        this.dates =
          (this.datesAvailabilities &&
            this.datesAvailabilities[this.currentMonth]) ||
          null;
      } catch (err) {
        isCanceled = axios.isCancel(err);
      } finally {
        if (!isCanceled) {
          this.isDatesLoading = false;
        }
      }
    },
    async handleSubmit(isSkipPayment) {
      let isConfirmed = true;
      if (this.isEditing && !isSkipPayment && this.additionalAmount < 0) {
        // Confirm refund
        isConfirmed = await dialog.confirm({
          title: `Refund $${Math.abs(this.additionalAmount / 100).toFixed(2)}?`,
          okText: "Refund",
          cancelText: "Cancel",
        });
      }
      if (!isConfirmed) {
        return;
      }
      this.isSkipPayment = isSkipPayment;
      const data = {
        ...this.form,
        slots: this.form.slots.map((slot) => {
          return {
            ...slot,
            price: this.checkoutPrice.subtotal / (this.maxPlayers || 1) / 100,
          };
        }),
        isDeposit: !!this.isDeposit?.value,
        customPrice: this.pureCustomPrice,
        upsellItems: this.upsellItems
          .filter((item) => item.quantity > 0)
          .map(({ id, quantity }) => ({
            id,
            quantity,
          })),
        selectedGroup: this.selectedGroup?.value,
      };

      if (this.form.isWalkIn) {
        data.slots = [getWalkInTimeSave(this.walkInTime, this.venueUtcOffset)];
      }
      const { option: withoutPaymentOption } = isSkipPayment
        ? await withoutPaymentOptionDialog.open()
        : {};
      if (withoutPaymentOption !== null) {
        data.withoutPaymentOption = withoutPaymentOption;

        this.$emit(
          "submit",
          data,
          this.isEditing ? Math.abs(this.additionalAmount / 100) : undefined,
          isSkipPayment
        );
      }
    },
    async calculatePrice() {
      const playersCount =
        this.selectedProduct?.typeOfPricing === BookingPricingTypeEnum.PERSON
          ? this.innerPlayersCount
          : this.selectedGroup
          ? this.groupCount
          : null;
      if (
        !this.form.productId ||
        (!this.form.slots?.length && this.customPrice === null) ||
        !playersCount
      ) {
        if (!this.isEditing) this.checkoutPrice = null;
        this.editingPrice = null;
        return;
      }
      let isCanceled = false;
      try {
        this.isPriceLoading = true;
        const priceParams = {
          isDeposit: !!this.isDeposit?.value,
          productId: this.form.productId,
          isWalkIn: this.form.isWalkIn,
          isFullFirstPayment: this.form.isFullFirstPayment,
          upsellItems: this.upsellItems
            .filter((item) => item.quantity > 0)
            .map((item) => `${item.id}:${item.quantity}`),
          ...(this.customPrice !== null
            ? {
                customPrice: this.pureCustomPrice,
              }
            : this.selectedProduct.typeOfPricing ===
              BookingPricingTypeEnum.PERSON
            ? {
                slots: this.form.slots
                  .filter((slot) => slot.id)
                  .map((slot) => slot.id),
                customSlotsCount: this.form.slots.filter((slot) => !slot.id)
                  .length,
                playersCount: this.innerPlayersCount,
              }
            : {
                slots: this.form.slots
                  .filter((slot) => slot.id)
                  .map((slot) => slot.id),
                customSlotsCount: this.form.slots.filter((slot) => !slot.id)
                  .length,
                playersCount: this.groupCount,
                selectedGroupId: this.form.selectedGroupId,
              }),
          ...(this.isEditing
            ? {
                promocode: this.bookingPrice?.appliedPromocode?.code,
              }
            : {}),
        };

        const newPrice = await this.getPrice(priceParams);
        if (newPrice) {
          if (this.isEditing) {
            this.editingPrice =
              newPrice.total +
                this.checkoutPrice.upsellItems?.reduce(
                  (prev, item) => prev + item.amount,
                  0
                ) || 0;
            this.checkoutPrice = {
              ...newPrice,
              upsellItems: this.bookingPrice.upsellItems,
              upsellTaxes: this.bookingPrice.upsellTaxes,
              paid: this.bookingPrice.paid,
              refunded: this.bookingPrice.refunded,
              total: this.editingPrice,
            };
          } else {
            this.checkoutPrice = newPrice;
          }
        }
      } catch (e) {
        isCanceled = axios.isCancel(e);
      } finally {
        if (!isCanceled) {
          this.isPriceLoading = false;
        }
      }
    },
    async refetchDatesAvailabilities() {
      this.REMOVE_FROM_DATES_AVAILABILITIES();
      await this.fetchAvailabilitiesDates(this.currentMonth);
    },
    toggleCustomPrice() {
      if (this.customPrice !== null) {
        this.customPrice = null;
      } else {
        this.customPrice =
          (this.checkoutPrice?.subtotal || 0) / 100 ||
          this.selectedProduct?.fixedPrice ||
          (this.selectedProduct?.price || 0) * this.innerPlayersCount;
      }
    },
    openTerms() {
      this.$modal.show(this.termsModalName);
    },
    handleUpsellQuantityChange(item, quantity) {
      item.quantity = quantity;
      if (this.upsellQuantityTimeout) {
        clearTimeout(this.upsellQuantityTimeout);
      }
      this.upsellQuantityTimeout = setTimeout(() => {
        this.calculatePrice();
        this.upsellQuantityTimeout = null;
      }, 500);
    },
    generateWalkInTime(time) {
      const fromMinutes = getMinutes(time);
      const hours = Math.floor(fromMinutes / 60);
      const hoursFormat = getVenueTimeFormatHours();
      const ampmHours = hoursFormat === 12 && hours > 12 ? hours - 12 : hours;
      const formattedHours = ampmHours >= 10 ? ampmHours : `0${ampmHours}`;
      const minutes = fromMinutes % 60;
      const formattedMinutes = minutes >= 10 ? minutes : `0${minutes}`;

      if (isNaN(minutes) || isNaN(hours)) return fromMinutes;

      return {
        time: `${formattedHours}:${formattedMinutes}`,
        activeTime: hours >= 12 ? "pm" : "am",
      };
    },
    initWalkInTime(from, to) {
      const startMoment = (
        from
          ? moment.utc().startOf("date").hour(from.hours).minute(from.minutes)
          : moment.utc()
      ).add(this.venueUtcOffset * 60, "minutes");
      const fromTime = {
        hours: startMoment.hours(),
        minutes: startMoment.minutes(),
      };

      const endMoment = to
        ? moment
            .utc()
            .startOf("date")
            .hour(to.hours)
            .minute(to.minutes)
            .add(this.venueUtcOffset * 60, "minutes")
        : startMoment.add(this.selectedProduct?.duration || 60, "minutes");
      const toTime = {
        hours: endMoment.hours(),
        minutes: endMoment.minutes(),
      };

      this.walkInTime = {
        from: this.generateWalkInTime(fromTime),
        to: this.generateWalkInTime(toTime),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.bookingForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 64px;
  height: 100%;

  &__label {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #2c3b55;
    margin-bottom: 10px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__deposit {
    max-width: 532px;
  }

  &__productRow {
    display: flex;
    align-items: flex-end;
    gap: 20px;

    > :first-child {
      flex: 1;
    }

    > :last-child {
      margin: 21px 0;
    }
  }

  &__customPrice {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      align-items: flex-start;
      gap: 10px;
      flex-wrap: wrap;

      > * {
        flex: 1 0 200px;
      }
    }
  }

  &__button {
    width: auto;
    min-width: 256px;
  }

  &__terms {
    display: flex;
    align-items: center;
    gap: 12px;

    &__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $secondary-500;

      &__link {
        color: $primary;
        text-decoration: underline;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          color: $primary-hover;
        }
      }
    }
  }

  &__additional-settings {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      justify-content: space-between;
    }

    &__checkboxes {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (min-width: $media-laptop) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
    }
  }

  .m-warning {
    padding: 12px;
    border-radius: 8px;
    animation: bounceBorder 1s ease-in-out infinite alternate;
  }
}

@keyframes bounceBorder {
  from {
    box-shadow: 0 1px 10px #dd5252;
  }
  to {
    box-shadow: 0 1px 5px #dd5252;
  }
}
</style>
