var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"grid-2 mb-24"},[_vm._l((_vm.sortedFields.filter(
        (field) => !_vm.isFieldMultiple(field) && !_vm.isFieldFlag(field)
      )),function(field){return [_c('FormItem',{key:`${field.key}_1`,attrs:{"rules":_vm.getFieldRules(field)},scopedSlots:_vm._u([{key:"default",fn:function({ isError }){return [(_vm.isFieldSelect(field))?_c('Select',{attrs:{"value":_vm.customSelectOptionMap[field.key]
              ? _vm.CUSTOM_SELECT_OPTION
              : field.value,"options":_vm.getFieldOptions(field),"placeholder":field.label,"label":_vm.hasLabels ? field.label : undefined,"is-error":isError,"is-dark-border":false},on:{"input":function($event){return _vm.handleSelect(field.key, $event)}}}):_c('Input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.getFieldMask(field)),expression:"getFieldMask(field)"}],attrs:{"value":field.value,"placeholder":field.label,"label":_vm.hasLabels ? field.label : undefined,"max":field.isRestrictFutureDates ? _vm.currentDate : '',"isDate":_vm.isFieldDate(field),"hint":field.hint,"isTextInput":_vm.isFieldDate(field),"dateFormat":_vm.dateFormat,"is-error":isError},on:{"input":function($event){return _vm.handleInput(field.key, $event)}}})]}}],null,true)}),(
          _vm.isFieldSelect(field) &&
          field.isAllowCustomOption &&
          _vm.customSelectOptionMap[field.key]
        )?_c('FormItem',{key:`${field.key}_2`,attrs:{"rules":_vm.getFieldRules(field)},scopedSlots:_vm._u([{key:"default",fn:function({ isError }){return [_c('Input',{attrs:{"value":field.value,"placeholder":`${field.label} (Your variant)`,"label":_vm.hasLabels ? `${field.label} (Your variant)` : undefined,"hint":field.hint,"is-error":isError},on:{"input":function($event){return _vm.handleInput(field.key, $event)}}})]}}],null,true)}):_vm._e()]})],2),_vm._l((_vm.sortedFields.filter(_vm.isFieldMultiple)),function(field){return _c('FormItem',{key:field.key,staticClass:"mb-24",attrs:{"rules":_vm.getFieldRules(field)}},[_c('CheckboxGroup',{attrs:{"value":field.value,"options":_vm.getFieldOptions(field),"label":field.label},on:{"input":function($event){return _vm.handleInput(field.key, $event)}}})],1)}),_vm._l((_vm.sortedFields.filter(_vm.isFieldFlag)),function(field){return _c('FormItem',{key:field.key,staticClass:"mb-24",attrs:{"rules":_vm.getFieldRules(field)}},[_c('Checkbox',{attrs:{"value":field.value},on:{"input":function($event){return _vm.handleInput(field.key, $event)}}},[_vm._v(" "+_vm._s(field.label)+" ")])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }