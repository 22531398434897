<template>
  <div class="calendarField">
    <div v-if="isLoading" class="overlay-loader">
      <Loader size="m" color="primary" />
    </div>
    <p class="calendarField__label">{{ label }}</p>
    <div class="calendarField__field" :class="{ 'm-disabled': isDisabled, 'm-warning': isHighlight }">
      <span :class="['calendarField__text', { 'm-placeholder': !value }]">
        {{ value || placeholder }}
      </span>
      <div v-if="isClear" class="calendarField__clear">
        <Icon
          name="close"
          is-clickable
          color="secondary-400"
          :size="20"
          @click.stop="$emit('clear')"
        />
      </div>
      <Icon name="calendar" color="secondary-500" />
    </div>
  </div>
</template>
<script>
export default {
  name: "CalendarField",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    isClear: {
      type: Boolean,
      default: false,
    },
    isHighlight: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
@keyframes bounceBorder {
  from {
    border-width: 2px;
    border-style: solid;
    border-color: #ff4141;
    box-shadow: 0 1px 10px #dd5252;
  } to {
    box-shadow: 0 1px 5px #dd5252;
    border-color: #c2cee3;
  }
}

.calendarField {
  position: relative;
  background: $background-color;

  &__field {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid $secondary-400;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.3s;
    background: $white;

    &.m-warning {
      animation: bounceBorder 1s ease-in-out infinite alternate
    }

    &.m-disabled {
      background: $secondary-100;
      color: $secondary-400;
      cursor: not-allowed;
    }
  }

  &__label {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: $secondary-500;
    margin-bottom: 10px;
  }

  &__text {
    color: $secondary-500;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &.m-placeholder {
      color: $secondary-400;
    }
  }

  &__clear {
    margin-left: auto;
    margin-right: 8px;
    margin-bottom: -5px;
  }
}
</style>
