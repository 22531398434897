<template>
  <div class="upsell-item-card">
    <div v-if="image" class="upsell-item-card__image">
      <img :src="image" alt="" />
    </div>
    <div class="upsell-item-card__content">
      <div class="upsell-item-card__top">
        <Title :level="2">{{ title }}</Title>
        <div v-if="!isCounter" class="upsell-item-card__activity">
          <span
            :class="[
              'upsell-item-card__activity__status',
              isPaused ? 'm-paused' : 'm-running',
            ]"
          >
            {{ isPaused ? "paused" : "active" }}
          </span>
          <Guard permission="upsellItems.pause" v-slot="{ isAvailable }">
            <Icon
              v-if="isAvailable"
              :name="isPaused ? 'play' : 'pause'"
              color="secondary-400"
              is-clickable
              @click="$emit('pause')"
            />
          </Guard>
        </div>
      </div>
      <p class="upsell-item-card__description">
        {{ description || "&nbsp;" }}
      </p>
      <div class="upsell-item-card__bottom">
        <span class="upsell-item-card__price">
          Price:
          <span class="upsell-item-card__price__value">${{ price }}</span>
        </span>
        <div v-if="!isCounter" class="upsell-item-card__actions">
          <Guard permission="upsellItems.delete" v-slot="{ isAvailable }">
            <Icon
              v-if="isAvailable"
              name="trash"
              color="secondary-400"
              is-clickable
              @click="$emit('remove')"
            />
          </Guard>
          <Icon
            name="edit"
            color="secondary-400"
            is-clickable
            @click="$emit('edit')"
          />
          <Guard permission="upsellItems.create" v-slot="{ isAvailable }">
            <Icon
              v-if="isAvailable"
              name="clipboard"
              color="secondary-400"
              is-clickable
              @click="$emit('duplicate')"
            />
          </Guard>
        </div>
      </div>
      <div v-if="isLoading || isDeleting" class="overlay-loader">
        <Loader size="m" :color="isDeleting ? 'danger' : 'primary'" />
      </div>
    </div>
    <div v-if="isCounter" class="upsell-item-card__counter">
      <button
        type="button"
        class="upsell-item-card__counter__button"
        :disabled="innerValue <= 0"
        @click="innerValue = Math.max(0, innerValue - 1)"
      >
        <Icon name="minus" :size="18" color="secondary-500" />
      </button>
      {{ innerValue }}
      <button
        type="button"
        class="upsell-item-card__counter__button"
        @click="innerValue += 1"
      >
        <Icon name="plus" :size="18" color="secondary-500" />
      </button>
    </div>
  </div>
</template>

<script>
import Guard from "@/components/common/Guard";
export default {
  name: "UpsellItemCard",
  components: { Guard },
  props: {
    value: {
      // quantity
      type: Number,
      default: 0,
    },
    image: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    price: {
      type: [String, Number],
      default: 0,
    },
    isPaused: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
    },
    isDeleting: {
      type: Boolean,
    },
    isCounter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.upsell-item-card {
  padding: 16px;
  box-shadow: $box-shadow-small;
  border: 2px solid $secondary-400;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 12px;

  &__image {
    background-color: $white;
    border: 1px solid $secondary-300;
    border-radius: 8px;
    height: 75px;
    width: 75px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: $media-tablet) {
      height: 100px;
      width: 100px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 12px;
  }

  &__activity {
    display: flex;
    align-items: center;
    gap: 10px;

    &__status {
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;

      &.m-paused {
        color: $danger;
      }

      &.m-running {
        color: $success;
      }
    }
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    color: $secondary-500;
    margin-bottom: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: auto;
  }

  &__price {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $secondary-400;

    &__value {
      color: $secondary-500;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__counter {
    display: flex;
    align-items: center;
    gap: 16px;
    font-size: 16px;
    line-height: 24px;
    color: $secondary-500;

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border-radius: 6px;
      border: 1px solid $secondary-400;
      padding: 12px 16px;
      cursor: pointer;

      &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
      }
    }
  }
}
</style>
