<template>
  <popper
    ref="popper"
    trigger="clickToToggle"
    append-to-body
    :options="{
      placement: 'bottom-start',
      modifiers: { offset: { offset: '0,6' } },
    }"
    :disabled="isDisabled"
    @show="isShowed = true"
    @hide="isShowed = false"
  >
    <div class="bookingCalendarPopper">
      <div class="bookingCalendarPopper__calendar">
        <calendar-view
          ref="calendar"
          :date="selectedDate"
          :dates="dates"
          :is-loading="isLoading"
          :firstDayOfWeek="6"
          variant="secondary"
          @selectDate="handleDateSelect"
          @date-change="$emit('date-change', $event)"
        />
      </div>
      <div class="bookingCalendarPopper__timeSlots">
        <time-slot
          v-if="
            !isLoading && !isSlotsLoading && allSlots.length && selectedDate
          "
          :free-slots="freeSlots"
          :all-slots="getArrayWithUTCOffset(allSlots)"
          :selected-slots="chosenSlotsWithUTCOffset || []"
          :players-count="+playersCount || 1"
          :hours-format="hoursFormat"
          @select="handleSlotSelect"
        />
        <div v-else class="bookingCalendarPopper__loader">
          <Loader v-if="isSlotsLoading || isLoading" color="primary" size="l" />
          <div
            v-else-if="!allSlots.length"
            class="bookingCalendarPopper__emptyText"
          >
            {{ $t("There is no slots for this date") }}
          </div>
          <div
            v-else-if="!selectedDate"
            class="bookingCalendarPopper__emptyText"
          >
            {{ $t("No date selected") }}
          </div>
        </div>
        <Form
          v-if="product"
          class="bookingCalendarPopper__customSlot"
          v-slot="{ invalid }"
        >
          <template v-if="isCustomSlot">
            <TimeInput
              :value="customStartTime"
              label="Start time"
              placeholder="10:55"
              has-time-buttons
              :active-time.sync="customActiveTime"
              class="bookingCalendarPopper__customSlot__time"
              :is-nano="true"
              @input="handleInputCustomTime"
            />
            <div class="bookingCalendarPopper__customSlotRow">
              <TimeInput
                :value="customDuration"
                label="Duration"
                placeholder="00:15"
                class="bookingCalendarPopper__customSlot__time"
                :is-nano="true"
                @input="handleInputCustomDuration"
              />
              <TimeInput
                :value="customEndTime"
                label="End time"
                placeholder="10:55"
                :is-disabled="true"
                class="bookingCalendarPopper__customSlot__time"
                :is-nano="true"
              />
            </div>
          </template>
          <div class="bookingCalendarPopper__customSlot__actions">
            <Button
              v-if="isCustomSlot"
              :is-disabled="invalid"
              is-small
              is-block
              class="bookingCalendarPopper__customSlot__button"
              @click="handleCustomSlot"
            >
              Save
            </Button>
            <Button
              variant="secondary"
              is-outlined
              is-small
              is-block
              class="bookingCalendarPopper__customSlot__button"
              @click="toggleCustomSlot"
            >
              {{ isCustomSlot ? "Cancel" : "Set custom time" }}
            </Button>
          </div>
        </Form>
      </div>
    </div>
    <calendar-field
      slot="reference"
      :label="label"
      :value="formattedTime"
      :isHighlight="isHighLight"
      :placeholder="$t('Choose a slots')"
      :is-loading="isSlotsLoading || isLoading"
      :is-disabled="isDisabled"
    />
  </popper>
</template>
<script>
import {
  getAvailabilitySlotTime,
  getFormattedSlots,
  getVenueTimeFormatHours,
} from "@/helpers/utils";
import Popper from "vue-popperjs";
import moment from "moment";
import { mapActions, mapState } from "vuex";
import {
  getMinutes,
  getSlotTimeFromMinutes,
  getTimezoneOffsetHours,
} from "@/helpers/utils";
import { Components } from "@zteam/booking-axe-sb";
import CalendarField from "@/components/common/CalendarField";
import { debounce } from "debounce";
import axios from "axios";
import TimeInput from "@/components/common/TimeInput.vue";

export default {
  name: "BookingCalendar",
  components: {
    TimeInput,
    CalendarField,
    Popper,
    CalendarView: Components.CalendarView,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    isHighLight: {
      type: Boolean,
      default: false,
    },
    baseSlots: {
      type: Array,
      default: null,
    },
    playersCount: {
      type: [String, Number],
      default: 1,
    },
    productId: {
      type: String,
      default: "",
    },
    product: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    dates: {
      type: [Array, Object],
      default: () => [],
    },
    maxPlayers: {
      type: Number,
    },
    minPlayers: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    isOpen: false,
    isShowed: false,
    isSlotsLoading: false,
    isInitialSlotsFetched: false,
    isCustomSlot: false,
    customStartTime: "",
    customDuration: "",
    customActiveTime: "am",
  }),
  computed: {
    ...mapState({
      slots: (state) => state.bookings.freeSlots,
      allSlots: (state) => state.bookings.allSlots,
      venue: (state) => state.venues.selectedVenue,
    }),
    hoursFormat() {
      return getVenueTimeFormatHours();
    },
    iconColor() {
      return "secondary-500";
    },
    formattedDate() {
      return this.value.date
        ? moment(this.value.date, "YYYY-MM-DD").format("ddd MMM D, YYYY")
        : "";
    },
    formattedTime() {
      return this.value.date
        ? `${this.formattedDate} ${getFormattedSlots(
            this.value,
            this.venue.timezone
          )}`
        : "";
    },
    chosenSlotsWithUTCOffset() {
      return this.getArrayWithUTCOffset(this.value.slots);
    },
    freeSlots() {
      return this.getArrayWithUTCOffset(
        this.slots.concat(this.baseSlots ? this.baseSlots : [])
      );
    },
    customEndTime() {
      const [startHour, startMinute] = this.customStartTime.split(":");
      if (!startHour || !startMinute) return "";
      const startMinutes = +startHour * 60 + +(startMinute || 0);

      const [startHourDuration, startMinuteDuration] =
        this.customDuration.split(":");
      const durationMinutes =
        +startHourDuration * 60 + +(startMinuteDuration || 0);

      return this.getTimeFromMinutes(startMinutes + durationMinutes);
    },
    selectedDate() {
      if (!this.value.date) return null;

      const date = moment(this.value.date, "YYYY-MM-DD").toDate();

      return {
        date: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
      };
    },
  },
  watch: {
    allSlots() {
      this.clearAbsentSlots();
    },
    playersCount: debounce(async function () {
      await this.fetchSlots();
      this.clearAbsentSlots();
    }, 400),
    "value.date"() {
      this.fetchSlots();
    },
    productId() {
      this.fetchSlots();
    },
  },
  mounted() {
    this.fetchSlots();
  },
  methods: {
    ...mapActions({
      getFreeSlots: "bookings/getFreeSlots",
    }),
    getTimeFromMinutes(time) {
      const hours = Math.floor(time / 60);
      const formattedHours = hours >= 10 ? hours : `0${hours}`;
      const minutes = time % 60;
      const formattedMinutes = minutes >= 10 ? minutes : `0${minutes}`;

      if (isNaN(minutes) || isNaN(hours)) return time;

      return `${formattedHours}:${formattedMinutes}`;
    },
    handleSlotSelect(value) {
      if (this.isCustomSlot) {
        this.toggleCustomSlot();
      }
      this.$emit("input", {
        date: this.value.date,
        slots: value
          .map((slot) => {
            const utcOffset = getTimezoneOffsetHours(this.venue.timezone);
            return {
              ...slot,
              from: getSlotTimeFromMinutes(
                getMinutes(slot.from) - utcOffset * 60
              ),
              to: getSlotTimeFromMinutes(getMinutes(slot.to) - utcOffset * 60),
            };
          })
          .sort((a, b) => getMinutes(a.from) - getMinutes(b.from)),
      });

      this.isShowed = false;
      this.$refs.popper?.doClose();
    },
    getArrayWithUTCOffset(array) {
      return (
        array?.map((slot) => {
          const utcOffset = getTimezoneOffsetHours(this.venue.timezone);
          return {
            ...slot,
            from: utcOffset
              ? getSlotTimeFromMinutes(getMinutes(slot.from) + utcOffset * 60)
              : slot.from,
            to: utcOffset
              ? getSlotTimeFromMinutes(getMinutes(slot.to) + utcOffset * 60)
              : slot.to,
          };
        }) || []
      );
    },
    async handleDateSelect(date) {
      if (!this.isShowed) return;

      if (date) {
        const isSameDate =
          this.selectedDate &&
          this.selectedDate.year === date.year &&
          this.selectedDate.month === date.month &&
          this.selectedDate.date === date.date;
        if (!isSameDate) {
          const chosenDate = moment(
            new Date(date.year, date.month, date.date)
          ).format("YYYY-MM-DD");
          this.$emit("input", {
            date: chosenDate,
            slots: [],
          });
        }
      }
    },
    async fetchSlots() {
      if (
        this.value.date &&
        this.productId &&
        (!this.isInitialSlotsFetched ||
          (this.playersCount >= this.minPlayers &&
            (!this.maxPlayers || this.playersCount <= this.maxPlayers)))
      ) {
        let isCanceled = false;
        try {
          this.isSlotsLoading = true;
          const bookingId = this.$route.params.id;
          await this.getFreeSlots({
            date: this.value.date,
            playersCount: this.playersCount,
            bookingId,
            productId: this.productId,
          });
          this.isInitialSlotsFetched = true;
        } catch (err) {
          isCanceled = axios.isCancel(err);
        } finally {
          if (!isCanceled) {
            this.isSlotsLoading = false;
          }
        }
      }
    },
    clearAbsentSlots() {
      const slots = this.value.slots.filter(
        (slot) =>
          !slot.id ||
          this.slots.some(
            (item) => getMinutes(slot.from) === getMinutes(item.from)
          )
      );
      if (slots.length !== this.value.slots.length) {
        this.$emit("input", {
          date: this.value.date,
          slots,
        });
      }
    },
    toggleCustomSlot() {
      this.isCustomSlot = !this.isCustomSlot;
      this.customActiveTime = "am";
      this.customStartTime = "";
      this.$emit("input", {
        date: this.value.date,
        slots: [],
      });
    },
    handleInputCustomTime(value) {
      this.customStartTime = value;
    },
    handleInputCustomDuration(value) {
      this.customDuration = value;
    },
    handleCustomSlot() {
      if (this.product) {
        const [startHour, startMinute] = this.customStartTime.split(":");
        const [startHourDuration, startMinuteDuration] =
          this.customDuration.split(":");
        if (startHour && startMinute) {
          const hours = getAvailabilitySlotTime(
            startHour,
            !this.customActiveTime || this.customActiveTime === "am"
          );
          const utcOffset = getTimezoneOffsetHours(this.venue.timezone);
          const startMinutes = hours * 60 + +startMinute - utcOffset * 60;
          const durationMinutes =
            +startHourDuration * 60 + +(startMinuteDuration || 0);
          const from = getSlotTimeFromMinutes(startMinutes);
          const to = getSlotTimeFromMinutes(startMinutes + durationMinutes);
          const customSlot = {
            dateIso: moment.utc(this.value.date).toISOString(),
            from,
            to,
            fixedPrice: +(this.product.fixedPrice || 0),
            price: +(this.product.price || 0),
            breakTime: +(this.product.breakTime || 0),
            isPrivate: true,
            isVisibleOnline: false,
          };
          this.$emit("input", {
            date: this.value.date,
            slots: [customSlot],
          });
          this.isShowed = false;
          this.$refs.popper?.doClose();
        }
      }
    },
  },
};
</script>
<style lang="scss">
.bookingCalendarPopper {
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow-mini;
  border-radius: 8px;
  overflow: hidden;
  background-color: $white;
  width: calc(100% - 40px);
  z-index: 10;

  @media (min-width: $media-laptop) {
    max-width: calc(100% - 40px);
    width: 1000px;
    flex-direction: row;
  }

  .popper__arrow {
    display: none;
  }

  &__calendar {
    background-color: $secondary-500;

    .date-picker {
      max-width: 100%;
      min-width: 100%;
      width: 100%;

      @media (min-width: $media-laptop) {
        max-width: 702px;
        min-width: 702px;
      }
    }
  }

  &__timeSlots {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 280px;
    background-color: $white;
    flex: 1;
    padding-bottom: 16px;

    @media (min-width: $media-laptop) {
      max-height: 390px;
    }

    @media (min-width: $media-laptop) {
      .timeslots__slots {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .timeslots__slot {
          min-width: unset;
        }
      }
    }
  }

  &__loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0 32px;
    flex: 1;
  }

  &__emptyText {
    padding: 24px 0 11px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(44, 59, 85, 0.34);
  }

  &__customSlot {
    padding: 0 24px;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__actions {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__button {
      min-width: unset !important;
    }
  }

  &__customSlotRow {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;

    @media (min-width: $media-laptop) {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>
