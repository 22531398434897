<template>
  <CustomModal :name="name" :classes="['terms-modal']">
    <template v-slot:header>
      <BackTitle class="mb-40" @click="$modal.hide(name)">
        Terms & Conditions
      </BackTitle>
    </template>
    <div class="terms-modal__body">
      <p v-html="text" />
    </div>
  </CustomModal>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
export default {
  name: "TermsModal",
  components: { BackTitle },
  props: {
    name: {
      type: String,
      default: "termsModal",
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.terms-modal {
  padding: 32px 20px;
  background-color: $white;
  overflow: hidden;
  max-width: calc(100% - 24px);
  margin: 0 auto;

  @media (min-width: $media-laptop) {
    padding: 40px 32px;
  }
}
</style>

<style lang="scss" scoped>
.terms-modal {
  &__body {
    overflow: auto;
    font-size: 12px;
    line-height: 20px;
    color: $secondary-500;
    max-height: 580px;

    @media (min-width: $media-laptop) {
      max-height: 340px;
    }
  }
}
</style>
