<template>
  <div>
    <div class="grid-2 mb-24">
      <template
        v-for="field in sortedFields.filter(
          (field) => !isFieldMultiple(field) && !isFieldFlag(field)
        )"
      >
        <FormItem
          :key="`${field.key}_1`"
          :rules="getFieldRules(field)"
          v-slot="{ isError }"
        >
          <Select
            v-if="isFieldSelect(field)"
            :value="
              customSelectOptionMap[field.key]
                ? CUSTOM_SELECT_OPTION
                : field.value
            "
            :options="getFieldOptions(field)"
            :placeholder="field.label"
            :label="hasLabels ? field.label : undefined"
            :is-error="isError"
            :is-dark-border="false"
            @input="handleSelect(field.key, $event)"
          />
          <Input
            v-else
            :value="field.value"
            :placeholder="field.label"
            :label="hasLabels ? field.label : undefined"
            v-mask="getFieldMask(field)"
            :max="field.isRestrictFutureDates ? currentDate : ''"
            :isDate="isFieldDate(field)"
            :hint="field.hint"
            :isTextInput="isFieldDate(field)"
            :dateFormat="dateFormat"
            :is-error="isError"
            @input="handleInput(field.key, $event)"
          />
        </FormItem>
        <FormItem
          v-if="
            isFieldSelect(field) &&
            field.isAllowCustomOption &&
            customSelectOptionMap[field.key]
          "
          :rules="getFieldRules(field)"
          :key="`${field.key}_2`"
          v-slot="{ isError }"
        >
          <Input
            :value="field.value"
            :placeholder="`${field.label} (Your variant)`"
            :label="hasLabels ? `${field.label} (Your variant)` : undefined"
            :hint="field.hint"
            :is-error="isError"
            @input="handleInput(field.key, $event)"
          />
        </FormItem>
      </template>
    </div>
    <FormItem
      class="mb-24"
      v-for="field in sortedFields.filter(isFieldMultiple)"
      :key="field.key"
      :rules="getFieldRules(field)"
    >
      <CheckboxGroup
        :value="field.value"
        :options="getFieldOptions(field)"
        :label="field.label"
        @input="handleInput(field.key, $event)"
      />
    </FormItem>
    <FormItem
      class="mb-24"
      v-for="field in sortedFields.filter(isFieldFlag)"
      :key="field.key"
      :rules="getFieldRules(field)"
    >
      <Checkbox :value="field.value" @input="handleInput(field.key, $event)">
        {{ field.label }}
      </Checkbox>
    </FormItem>
  </div>
</template>

<script>
import { CustomFieldTypeEnum } from "@/helpers/enums";
import { mapState } from "vuex";

export default {
  name: "CustomFieldsInput",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    hasLabels: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CUSTOM_SELECT_OPTION: "CUSTOM_SELECT_OPTION",
      customSelectOptionMap: {},
    };
  },
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
    }),
    dateFormat() {
      return this.venue.dateFormat || "MM/DD/YY";
    },
    fields: {
      get() {
        return { ...this.value };
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    sortedFields() {
      return Object.entries(this.fields)
        .filter(([, value]) => !value.isDisabled)
        .map(([key, value]) => ({
          key,
          ...value,
        }))
        .sort((a, b) => a.sortWeight - b.sortWeight);
    },
    currentDate() {
      const offset = new Date().getTimezoneOffset();
      let formattedDate = new Date(new Date().getTime() - offset * 60 * 1000);
      return formattedDate.toISOString().split("T")[0];
    },
  },
  methods: {
    getFieldRules(field) {
      const rules = {};
      if (field.isRequired) {
        if (this.isFieldFlag(field)) {
          rules["required"] = { allowFalse: false };
        } else {
          rules["required"] = true;
        }
      }
      if (field.type === CustomFieldTypeEnum.EMAIL) {
        rules["email"] = true;
      } else if (field.type === CustomFieldTypeEnum.PHONE) {
        rules["phone"] = 17;
      } else if (field.type === CustomFieldTypeEnum.NUMERIC) {
        rules["numeric"] = true;
      }

      return rules;
    },
    getFieldMask(field) {
      if (field.type === CustomFieldTypeEnum.PHONE) {
        return "+1 (###) ###-####";
      }
    },
    isFieldDate(field) {
      return field.type === CustomFieldTypeEnum.DATE;
    },
    isFieldSelect(field) {
      return field.type === CustomFieldTypeEnum.SELECT;
    },
    isFieldMultiple(field) {
      return field.type === CustomFieldTypeEnum.MULTIPLE;
    },
    isFieldFlag(field) {
      return field.type === CustomFieldTypeEnum.FLAG;
    },
    getFieldOptions(field) {
      return [
        ...(field.options?.map((option) => ({
          value: option,
          name: option,
        })) || []),
        field.isAllowCustomOption && {
          value: this.CUSTOM_SELECT_OPTION,
          name: "Other",
        },
      ].filter(Boolean);
    },
    handleInput(fieldKey, value) {
      const copy = { ...this.fields };
      copy[fieldKey].value = value;
      this.fields = copy;
    },
    handleSelect(fieldKey, value) {
      if (
        this.fields[fieldKey]?.isAllowCustomOption &&
        value === this.CUSTOM_SELECT_OPTION
      ) {
        this.customSelectOptionMap[fieldKey] = true;
        this.handleInput(fieldKey, "");
      } else {
        delete this.customSelectOptionMap[fieldKey];
        this.handleInput(fieldKey, value);
      }
    },
  },
};
</script>
