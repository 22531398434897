<template>
  <div class="walk-in-time-input">
    <TimeInput
      v-model="from"
      label="Start time"
      placeholder="10:55"
      has-time-buttons
      :active-time.sync="fromActiveTime"
      class="walk-in-time-input__input m-flex"
    />
    <div class="walk-in-time-input__dash">-</div>
    <TimeInput
      v-model="to"
      label="End time"
      placeholder="10:55"
      has-time-buttons
      :active-time.sync="toActiveTime"
      class="walk-in-time-input__input m-flex"
    />
  </div>
</template>

<script>
import TimeInput from "@/components/common/TimeInput";
export default {
  name: "WalkInTimeInput",
  components: { TimeInput },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    from: {
      get() {
        return this.innerValue.from.time;
      },
      set(newValue) {
        this.innerValue = {
          ...this.innerValue,
          from: {
            ...this.innerValue.from,
            time: newValue,
          },
        };
      },
    },
    fromActiveTime: {
      get() {
        return this.innerValue.from.activeTime;
      },
      set(newValue) {
        this.innerValue = {
          ...this.innerValue,
          from: {
            ...this.innerValue.from,
            activeTime: newValue,
          },
        };
      },
    },
    to: {
      get() {
        return this.innerValue.to.time;
      },
      set(newValue) {
        this.innerValue = {
          ...this.innerValue,
          to: {
            ...this.innerValue.to,
            time: newValue,
          },
        };
      },
    },
    toActiveTime: {
      get() {
        return this.innerValue.to.activeTime;
      },
      set(newValue) {
        this.innerValue = {
          ...this.innerValue,
          to: {
            ...this.innerValue.to,
            activeTime: newValue,
          },
        };
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.walk-in-time-input {
  display: flex;
  align-items: flex-end;
  gap: 24px;

  &__input {
    flex: 1;
  }

  &__dash {
    font-size: 16px;
    line-height: 28px;
    color: $secondary-500;
    margin: 16px 0;
  }
}
</style>
